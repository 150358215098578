import toastr from 'toastr';

const emailjs = require('emailjs-com');

emailjs.init('user_h8NyBE1OvYJmyOFNrD9Sc');

// trim
if (!String.prototype.trim) {
  (function() {
    // Make sure we trim BOM and NBSP
    let rtrim = /^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g;
    String.prototype.trim = function() {
      console.log('TCL: String.prototype.trim -> trim');
      return this.replace(rtrim, '');
    };
  })();
}

// send mail
let send = document.querySelector('#send');
send.addEventListener('mouseup', () => {
  if (button.parentElement.classList.contains('expand')) {
    const fields = [document.querySelector('#name'), document.querySelector('#mail'), document.querySelector('#text')];

    if (isFormValid(fields)) {
      const templateParams = {
        from_name: document.querySelector('#name').value,
        message_html: buildMailBody(fields)
      };

      emailjs.send('smtp_server', 'template_Ozgxns1t', templateParams).then(
        function(response) {
          console.log('SUCCESS!', response.status, response.text);
          reset(fields);
          showToast("¡Thanks! ¡I'll answer you as soon as posible!");
          button.parentElement.classList.remove('expand');
        },
        function(error) {
          console.log('FAILED...', error);
          showToast('¡Douch! Something went wrong', 'error');
        }
      );
    } else {
      showToast('All fields are required', 'error');
    }
  } else {
    button.click();
  }
});

function isFormValid(fields) {
  let errorCount = 0;
  fields.forEach((field) => {
    if (field.value.trim() === '') {
      errorCount++;
    }
  });

  return errorCount === 0;
}

function showToast(message, severity) {
  toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: true,
    progressBar: true,
    positionClass: 'toast-top-right',
    preventDuplicates: true,
    onclick: null,
    showDuration: '300',
    hideDuration: '1000',
    timeOut: '5000',
    extendedTimeOut: '1000',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
  };
  switch (severity) {
    case 'error':
      toastr.error(message);
      break;
    case 'warning':
      toastr.warning(message);
      break;
    default:
      toastr.success(message);
      break;
  }
}

function reset(fields) {
  fields.forEach((f) => f.classList.remove('has-error'));
  document.querySelector('form').reset();
}

function buildMailBody(fields) {
  let body = '<html><body><ul>';
  fields.forEach((field) => {
    body += `<li>${field.id}: ${field.value}</li>`;
  });
  body += '</ul></body></html>';
  return body;
}

// button click event
const box = document.querySelector('.intro');
let button = document.querySelector('.social-button');
button.addEventListener('click', (evC) => {
  button.parentElement.classList.toggle('expand');
  evC.stopPropagation();
  if (button.parentElement.classList.contains('expand')) {
    document.querySelector('.social-container.expand').addEventListener('click', (evC2) => {
      evC2.preventDefault();
      evC2.stopPropagation();
    });
    box.addEventListener('click', () => {
      button.parentElement.classList.remove('expand');
    });
  } else {
    try {
      box.removeEventListener('click');
    } catch (error) {}
  }
});

// form input fields
let inputList = document.querySelectorAll('.social-body input');
inputList.forEach((input, idx) => setupSvgEffect(input, idx));

function setupSvgEffect(textInput, idx) {
  let line = '.line' + (idx + 1);
  let inputWrap = textInput.parentElement;
  let inputWidth = parseInt(getComputedStyle(inputWrap).width);
  let svgText = Snap(line);
  let qCurve = inputWidth / 2; // For correct curving on diff screen sizes
  let textPath = svgText.path('M0 0 ' + inputWidth + ' 0');

  let textDown = function() {
    textPath.animate({ d: 'M0 0 Q' + qCurve + ' 40 ' + inputWidth + ' 0' }, 150, mina.easeout);
  };
  let textUp = function() {
    textPath.animate({ d: 'M0 0 Q' + qCurve + ' -30 ' + inputWidth + ' 0' }, 150, mina.easeout);
  };
  let textSame = function() {
    textPath.animate({ d: 'M0 0 ' + inputWidth + ' 0' }, 200, mina.easein);
  };
  let textRun = function() {
    setTimeout(textDown, 200);
    setTimeout(textUp, 400);
    setTimeout(textSame, 600);
  };

  textInput.addEventListener('focus', function() {
    let parentDiv = this.parentElement;
    parentDiv.classList.add('active');
    textRun();

    this.addEventListener('blur', function() {
      let rg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.]{3,9})+\.([A-Za-z]{2,4})$/;
      this.value == 0 ? parentDiv.classList.remove('active') : null;

      if (this.name === 'mail') {
        !rg.test(this.value) && this.value != 0
          ? () => {
              parentDiv.classList.remove('valid');
              parentDiv.classList.add('invalid');
              parentDiv.style.transformOrigin = 'center';
            }
          : rg.test(this.value) && this.value != 0
          ? () => {
              parentDiv.classList.add('valid');
              parentDiv.classList.remove('invalid');
              parentDiv.style.transformOrigin = 'bottom';
            }
          : null;
      }
    });

    parentDiv.classList.remove('valid', 'invalid');
  });
}

// form textarea field
let textarea = document.querySelector('.textarea__field');

// in case the input is already filled..
if (textarea.value.trim() !== '') {
  textarea.parentNode.classList.add('textarea--filled');
}
// events:
textarea.addEventListener('focus', onInputFocus);
textarea.addEventListener('blur', onInputBlur);

function onInputFocus(ev) {
  ev.target.parentNode.classList.add('textarea--filled');
}

function onInputBlur(ev) {
  if (ev.target.value.trim() === '') {
    ev.target.parentNode.classList.remove('textarea--filled');
  }
}
